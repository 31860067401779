.ORANGE {
    a {
        @apply text-orange-500 hover:text-orange-400 no-underline;
    }
}

.GREEN {
    a {
        @apply text-green-500 hover:text-green-400 no-underline;
    }
}
